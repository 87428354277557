import axios from 'axios';
import './CustomerPortal.css';
import history from '../../app/history';
import { useAuth } from '../../context/AuthContext';
import { Fragment, useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { currencyFormatter } from '../components/upsell';
import {
  Drawer,
  Checkbox,
  Radio,
  Tooltip,
  Modal,
  Text,
  Flex,
} from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import loader from './loader.svg';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getVoucherify } from '../../redux/coupon/actions';
import { updateUserEmail } from '../../redux/user/actions';
import { a_PortalAction, a_PortalLoggedIn } from '../../api/analytics';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

const font = "'Plus Jakarta Sans', sans-serif";

const LoginForm = ({ loginEmail, setLoginEmail }) => {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  const handleEmailSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post('/api/auth/generateOtp', { email: loginEmail })
      .then(() => {
        setShowOTP(true);
      })
      .catch((error) => {
        setError(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOTPSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post('/api/auth/verifyOtp', { email: loginEmail, otp })
      .then(({ data }) => {
        login(data.data);
        dispatch(updateUserEmail(loginEmail));
        dispatch(getVoucherify(data.data.code, true));
      })
      .catch((error) => {
        setError(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="login"
      style={{
        backgroundColor: '#F7F0F7',
        flex: 1,
      }}
    >
      <div
        style={{
          gap: '8px',
          display: 'flex',
          padding: '16px',
          marginBottom: '2px',
          alignItems: 'center',
          backgroundColor: 'white',
          ...(!isMobile && {
            width: '600px',
            margin: '0 auto',
            marginBottom: '8px',
          }),
        }}
      >
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => history.push('/')}
        >
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 19.5 7.5 12 15 4.5"
              stroke="#1C3E64"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p
          style={{
            fontSize: 18,
            color: 'black',
            fontWeight: 900,
            margin: '0 auto',
            fontFamily: font,
          }}
        >
          Account Dashboard
        </p>
        <div style={{ display: 'flex', visibility: 'hidden' }}>
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="#ff2825"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            class="icon icon-tabler icon-tabler-logout"
            viewBox="0 0 24 24"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2" />
            <path d="M9 12h12l-3-3M18 15l3-3" />
          </svg>
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          padding: '24px',
          ...(!isMobile && {
            width: '600px',
            margin: '0 auto',
          }),
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 900,
            fontSize: '24px',
            fontFamily: font,
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Access Your Dashboard
        </p>
        {!showOTP ? (
          <div>
            <form
              onSubmit={handleEmailSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <label
                style={{
                  gap: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    fontSize: '18px',
                    fontFamily: font,
                  }}
                >
                  Email:
                </p>
                <input
                  style={{
                    width: '100%',
                    height: '50px',
                    outline: 'none',
                    fontSize: '18px',
                    fontFamily: font,
                    borderRadius: '4px',
                    padding: '15px 20px',
                    color: 'rgb(40, 78, 123)',
                    background: 'rgba(40, 78, 123, 0.05)',
                    border: '1px solid rgba(40, 78, 123, 0.1)',
                  }}
                  required
                  type="email"
                  value={loginEmail}
                  onChange={(e) => {
                    setError('');
                    setLoginEmail(e.target.value.toLowerCase());
                  }}
                />
              </label>
              {error && (
                <p
                  style={{
                    color: 'red',
                    textAlign: 'right',
                    fontFamily: font,
                    margin: 0,
                  }}
                >
                  {error}
                </p>
              )}
              <button
                type="submit"
                className={'popup-body-button'}
                disabled={loginEmail === '' || error || loading}
                style={{
                  height: '38px',
                  display: 'flex',
                  color: '#ffffff',
                  fontSize: '18px',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                  justifyContent: 'center',
                  boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
                }}
              >
                <div
                  style={{
                    margin: 0,
                    gap: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {loading && <img src={loader} alt="loader" />}
                  Login
                </div>
              </button>
            </form>
            <div
              style={{
                gap: '12px',
                display: 'flex',
                padding: '24px',
                fontSize: '16px',
                fontFamily: font,
                marginTop: '24px',
                textAlign: 'center',
                flexDirection: 'column',
                backgroundColor: 'white',
              }}
            >
              <p style={{ fontFamily: 'inherit', margin: 0 }}>
                By clicking "Login," you will receive a 6-digit OTP code to
                access the VIP Account Dashboard.
              </p>
              <p style={{ fontFamily: 'inherit', margin: 0 }}>
                For any queries, please contact us at <b>hi@storyboards.io</b>.
              </p>
            </div>
          </div>
        ) : (
          <Fragment>
            <div
              style={{
                fontFamily: font,
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              <p style={{ fontWeight: 700 }}>
                We`ve emailed you a 6-digit OTP code.
              </p>
              <p>Enter it below and press the button to continue.</p>
            </div>
            <form
              onSubmit={handleOTPSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    fontSize: '18px',
                    fontFamily: font,
                  }}
                >
                  OTP:
                </p>
                <input
                  style={{
                    width: '100%',
                    height: '50px',
                    outline: 'none',
                    fontSize: '18px',
                    fontFamily: font,
                    borderRadius: '4px',
                    padding: '15px 20px',
                    color: 'rgb(40, 78, 123)',
                    background: 'rgba(40, 78, 123, 0.05)',
                    border: '1px solid rgba(40, 78, 123, 0.1)',
                  }}
                  required
                  type="text"
                  value={otp}
                  minLength={6}
                  maxLength={6}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onChange={(e) => {
                    setError('');
                    setOTP(e.target.value);
                  }}
                />
              </label>
              {error && (
                <p
                  style={{
                    margin: 0,
                    color: 'red',
                    fontFamily: font,
                    textAlign: 'right',
                  }}
                >
                  {error}
                  <br />
                  <span
                    onClick={() => {
                      setOTP('');
                      setError('');
                      setShowOTP(false);
                    }}
                    style={{
                      color: 'rgba(0,0,0,.8)',
                      textDecoration: 'underline',
                    }}
                  >
                    Retry...
                  </span>
                </p>
              )}
              <button
                type="submit"
                className={'popup-body-button'}
                disabled={otp === '' || error || loading}
                style={{
                  height: '38px',
                  display: 'flex',
                  color: '#ffffff',
                  fontSize: '18px',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                  justifyContent: 'center',
                  boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
                }}
              >
                <div
                  style={{
                    margin: 0,
                    gap: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {loading && <img src={loader} alt="loader" />}
                  Submit
                </div>
              </button>
            </form>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export const CustomerPortal = () => {
  let { user, logout: logoutUser } = useAuth();
  const [subData, setSubData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const { loggedIn, logout } = useAuthenticationContext();
  const [performedAction, setPerformedAction] = useState(false);
  const [skipMonthOpened, setSkipMonthOpened] = useState(false);
  const [requestRefundOpened, setRequestRefundOpened] = useState(false);
  const [pauseMembershipOpened, setPauseMembershipOpened] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);

  user = user || loggedIn;
  const email = (user?.metadata?.customer_email || loginEmail).toLowerCase();

  useEffect(() => {
    if (!user) {
      return;
    }
    async function get() {
      const response = await axios.post('/api/customer/sub-data', {
        email,
      });
      setSubData(response.data.data);
    }
    try {
      get();
      a_PortalLoggedIn();
    } catch (error) {}
  }, [user, skipMonthOpened, requestRefundOpened, pauseMembershipOpened]);

  const fullFillRequest = async (text) => {
    const action = `cancel_${text}`;
    try {
      setLoading(true);
      await axios.post('/api/auth/request', { email, request: action }, null);
      a_PortalAction(action);
      setPerformedAction(true);
      // notifications.show({
      //   title: 'Thank you',
      //   message:
      //     'Your request has been received and will be processed within the next 24 hours.',
      // });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <LoginForm loginEmail={loginEmail} setLoginEmail={setLoginEmail} />;
  }

  const isAnyPauseActive = subData?.skipped || subData?.paused;

  const now = new Date();
  const subscriptionDate = new Date(subData?.createdAt * 1000);
  subscriptionDate.setDate(subscriptionDate.getDate() + 30);
  const subscriptionIsMoreThanMonthOld =
    now.getTime() > subscriptionDate.getTime();

  return (
    <div
      className="portal"
      style={{
        backgroundColor: '#F7F0F7',
        ...(!isMobile && {
          flex: 1,
        }),
      }}
    >
      <div
        style={{
          gap: '8px',
          display: 'flex',
          padding: '16px',
          marginBottom: '2px',
          alignItems: 'center',
          backgroundColor: 'white',
          ...(!isMobile && {
            width: '40%',
            margin: '0 auto',
          }),
        }}
      >
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => history.push('/')}
        >
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 19.5 7.5 12 15 4.5"
              stroke="#1C3E64"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p
          style={{
            fontSize: 18,
            color: 'black',
            fontWeight: 900,
            margin: '0 auto',
          }}
        >
          Account Dashboard
        </p>
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => {
            logoutUser();
            logout();
          }}
        >
          <Tooltip label="Logout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-logout"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
              <path d="M9 12h12l-3 -3" />
              <path d="M18 15l3 -3" />
            </svg>
          </Tooltip>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          padding: '32px 16px',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'white',
          color: 'rgba(0,0,0,.8)',
          justifyContent: 'space-between',
          ...(!isMobile && {
            width: '40%',
            margin: '0 auto',
            marginTop: '8px',
          }),
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <p style={{ fontWeight: 800, fontSize: '28px' }}>
            Hi {user?.metadata?.customer_name}!
          </p>
          <p style={{ fontWeight: 'bold', color: '#AD37FD', fontSize: '16px' }}>
            {user?.metadata?.customer_email}
          </p>
        </div>
        <div
          style={{
            padding: '8px 12px',
            borderRadius: '32px',
            backgroundColor: '#AD37FD',
          }}
        >
          <p style={{ fontSize: 14, color: 'white', fontWeight: 800 }}>VIP</p>
        </div>
      </div>
      <div
        style={{
          padding: 24,
          display: 'flex',
          alignItems: 'center',
          margin: '24px 0 24px 0',
          backgroundColor: 'white',
          color: 'rgba(0,0,0,.8)',
          justifyContent: 'space-between',
          ...(!isMobile && {
            width: '40%',
            margin: '0 auto',
            marginTop: '24px',
            marginBottom: '24px',
          }),
        }}
      >
        <p style={{ fontSize: '18px', fontWeight: 900 }}>Store Credit</p>
        <div
          style={{
            height: '32px',
            display: 'flex',
            padding: '12px',
            fontWeight: 700,
            color: '#ffffff',
            fontSize: '14px',
            alignItems: 'center',
            borderRadius: '200px',
            justifyContent: 'center',
            backgroundColor: 'rgba(173, 55, 253, .5)',
          }}
        >
          {user?.code}
        </div>
        <p style={{ color: '#AD37FD', fontSize: '18px', fontWeight: 'bold' }}>
          {currencyFormatter(user?.amount / 100, 'USD', 2)}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          ...(!isMobile && {
            width: '40%',
            margin: '0 auto',
          }),
        }}
      >
        <div
          style={{
            gap: '8px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            color: 'rgba(0,0,0,.8)',
            backgroundColor: 'white',
            ...(!isMobile && {
              flex: 1,
              gap: '12px',
              flexDirection: 'row',
            }),
          }}
        >
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
              ...(!isMobile && {
                flex: 3,
              }),
            }}
          >
            {!subData?.skipped ? (
              <Fragment>
                <p style={{ fontWeight: 800, fontSize: '18px' }}>
                  Don`t feel like shopping this month?
                </p>
                <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                  Skip your next invoice and keep your perks!
                </p>
              </Fragment>
            ) : (
              <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                You have skipped this month! Until <b>{subData?.date}</b>
              </p>
            )}
          </div>
          <div
            onClick={() => {
              if (!subData || isAnyPauseActive) {
                return;
              }
              setSkipMonthOpened(true);
            }}
            style={{
              width: '100%',
              color: 'white',
              outline: 'none',
              fontWeight: 800,
              fontSize: '18px',
              fontFamily: font,
              marginTop: '8px',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '24px',
              padding: '16px 12px 16px 12px',
              backgroundColor:
                !subData || isAnyPauseActive ? '#e2e2e2' : '#AD37FD',
              ...(!isMobile && {
                flex: 1,
                marginTop: 'auto',
              }),
            }}
          >
            Skip the Month
          </div>
        </div>
        <div
          style={{
            gap: '8px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            color: 'rgba(0,0,0,.8)',
            backgroundColor: 'white',
            ...(!isMobile && {
              flex: 1,
              gap: '12px',
              flexDirection: 'row',
            }),
          }}
        >
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
              ...(!isMobile && {
                flex: 3,
              }),
            }}
          >
            {subData?.paused ? (
              <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                Your membership has been paused until <b>{subData?.date}</b>
              </p>
            ) : (
              <Fragment>
                <p style={{ fontWeight: 800, fontSize: '18px' }}>
                  Need a break?
                </p>
                <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                  We get it, sometimes you need a break. Pause your membership
                  and keep your perks!
                </p>
              </Fragment>
            )}
          </div>
          <div
            onClick={() => {
              if (!subData || isAnyPauseActive) {
                return;
              }
              setPauseMembershipOpened(true);
            }}
            style={{
              width: '100%',
              color: 'white',
              outline: 'none',
              fontWeight: 800,
              fontSize: '18px',
              fontFamily: font,
              marginTop: '8px',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '24px',
              padding: '16px 12px 16px 12px',
              backgroundColor:
                !subData || isAnyPauseActive ? '#e2e2e2' : '#AD37FD',
              ...(!isMobile && {
                flex: 1,
                marginTop: 'auto',
              }),
            }}
          >
            Pause Membership
          </div>
        </div>
        <div
          style={{
            gap: '8px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            color: 'rgba(0,0,0,.8)',
            backgroundColor: 'white',
            ...(!isMobile && {
              flex: 1,
              gap: '12px',
              flexDirection: 'row',
            }),
          }}
        >
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
              ...(!isMobile && {
                flex: 3,
              }),
            }}
          >
            {!subscriptionIsMoreThanMonthOld ? (
              <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                Refunds are not available currently, as you have already
                benefited from the VIP membership cashback offer. If you have
                any questions or need further assistance, please contact our
                support team at <b>hi@storyboards.io</b>.
              </p>
            ) : (
              <Fragment>
                <p style={{ fontWeight: 800, fontSize: '18px' }}>
                  One-click store credit refund
                </p>
                <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
                  If you meant to skip the month and forgot, no problem! We
                  offer a full refund on your last payment within 30 days of
                  purchase policy.
                </p>
              </Fragment>
            )}
          </div>
          <div
            onClick={() => {
              if (
                !subData ||
                subData?.refunded ||
                !subscriptionIsMoreThanMonthOld
              ) {
                return;
              }
              setRequestRefundOpened(true);
            }}
            style={{
              width: '100%',
              color: 'white',
              outline: 'none',
              fontWeight: 800,
              fontSize: '18px',
              fontFamily: font,
              marginTop: '8px',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '24px',
              padding: '16px 12px 16px 12px',
              backgroundColor:
                !subData || subData?.refunded || !subscriptionIsMoreThanMonthOld
                  ? '#e2e2e2'
                  : '#AD37FD',
              ...(!isMobile && {
                flex: 1,
                marginTop: 'auto',
              }),
            }}
          >
            {subData?.refunded
              ? 'Your refund has been requested. Please allow for up to 10 days to receive your refund.'
              : 'Request Refund'}
          </div>
        </div>
        <div
          style={{
            gap: '12px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            color: 'rgba(0,0,0,.8)',
            backgroundColor: 'white',
          }}
        >
          <p
            style={{
              fontWeight: 800,
              fontSize: '18px',
              marginBottom: '4px',
            }}
          >
            Your VIP Membership includes:
          </p>
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CheckIcon />
            <p>Exclusive access to new products</p>
          </div>
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CheckIcon />
            <p>Free photo touch-up service</p>
          </div>
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CheckIcon />
            <p>VIP-only sales & deals</p>
          </div>
          <div
            style={{
              gap: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CheckIcon />
            <p>FREE U.S Shipping & Returns</p>
          </div>
        </div>
        <div
          style={{
            padding: '24px',
            backgroundColor: 'white',
            gap: '16px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              fontSize: '18px',
              fontWeight: 900,
              color: 'rgba(0,0,0,.8)',
            }}
          >
            FAQ About VIP Membership
          </p>
          <a target="_blank" href="https://storyboards.io/membership-faq/">
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#AD37FD',
              }}
            >
              Membership FAQ
            </p>
          </a>
          {subData ? (
            <a
              onClick={() => {
                if (loading) {
                  return;
                }
                fullFillRequest();
              }}
              role="button"
              target="_blank"
              href={subData.url}
              style={{
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: 500,
                color: !subData ? 'e2e2e2' : '#AD37FD',
              }}
            >
              Cancel Membership
            </a>
          ) : (
            <Fragment>
              <a
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  open();
                  fullFillRequest('non-stripe');
                }}
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#AD37FD',
                  cursor: 'pointer',
                }}
              >
                Cancel Membership
              </a>
              <Modal opened={opened} onClose={close}>
                <Flex p={8} direction="column">
                  <Text
                    style={{
                      fontWeight: 600,
                      fontFamily: font,
                    }}
                  >
                    We're unable to process your VIP membership cancellation
                    currently, due to one of the following potential reasons:
                  </Text>
                  <ul
                    className="list"
                    style={{ fontSize: '14px', fontFamily: font }}
                  >
                    <li>
                      Pending First Charge: Your first membership charge has not
                      gone through yet, so cancellation will only be possible
                      once this has been processed successfully.
                    </li>
                    <li>
                      You Have Already Cancelled: Your membership has already
                      been cancelled and cannot be cancelled again.
                    </li>
                    <li>
                      Your Account Is In Default: Your account is in default
                      mode due to unpaid invoices. Please settle any outstanding
                      payments before cancelling.
                    </li>
                    <li>
                      You're A PayPal Customer: If you used PayPal for your
                      subscription, please log in to your PayPal account to
                      manage and cancel your membership.
                    </li>
                    <li>
                      You Do Not Have An Active Membership: There is currently
                      no active VIP membership associated with your account.
                    </li>
                  </ul>
                  <Text
                    style={{
                      fontSize: '14px',
                      fontFamily: font,
                    }}
                  >
                    If you need further assistance, please contact our support
                    team at hi@storyboards.io
                  </Text>
                </Flex>
              </Modal>
            </Fragment>
          )}
        </div>
      </div>
      <SkipMonthModal
        email={email}
        subData={subData}
        opened={skipMonthOpened}
        setOpened={setSkipMonthOpened}
        performedAction={performedAction}
        setPerformedAction={setPerformedAction}
      />
      <RequestRefundModal
        email={email}
        opened={requestRefundOpened}
        setOpened={setRequestRefundOpened}
        performedAction={performedAction}
        setPerformedAction={setPerformedAction}
      />
      <PauseMembershipModal
        email={email}
        subData={subData}
        opened={pauseMembershipOpened}
        setOpened={setPauseMembershipOpened}
        performedAction={performedAction}
        setPerformedAction={setPerformedAction}
      />
    </div>
  );
};

const CheckIcon = () => (
  <svg
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.1826 19.391L8.21882 19.3591C8.27878 19.3063 8.33556 19.2482 8.38848 19.1848L8.1826 19.391ZM8.1826 19.391L8.15636 19.4315C8.071 19.5631 7.96376 19.6846 7.83461 19.7905L7.8346 19.7905C7.16298 20.3412 6.16898 20.2469 5.61511 19.5817L5.6151 19.5817L0.709706 13.6906C0.709673 13.6906 0.70964 13.6905 0.709607 13.6905C0.157531 13.0268 0.251396 12.0447 0.920743 11.4952C1.59233 10.945 2.58674 11.0397 3.14018 11.7041C3.14019 11.7041 3.14021 11.7041 3.14022 11.7042L6.56755 15.8205L6.83649 16.1435L7.10547 15.8205L15.7689 5.41757C15.7689 5.41755 15.7689 5.41753 15.7689 5.41751C16.3233 4.75215 17.3178 4.65799 17.9888 5.20861L17.9889 5.2087C18.6585 5.75768 18.752 6.74064 18.2 7.40341L18.2 7.40343L8.38864 19.1846L8.1826 19.391Z"
      fill="#0B6B65"
      stroke="#D6F3D4"
      stroke-width="0.7"
    />
  </svg>
);

const SkipMonthModal = ({
  email,
  opened,
  subData,
  setOpened,
  performedAction,
  setPerformedAction,
}) => {
  const now = new Date();
  const next = now.setDate(now.getDate() + 55);
  const [loading, setLoading] = useState(false);

  const inMonthTime = new Date(next);

  const nextMonth = {
    day: inMonthTime.getDate(),
    year: inMonthTime.getFullYear(),
    month: inMonthTime.toLocaleString('default', { month: 'short' }),
    dayString: inMonthTime.toLocaleString('default', { weekday: 'short' }),
  };

  const nextMonthString = `${nextMonth.dayString}, ${nextMonth.month} ${nextMonth.day}, ${nextMonth.year}`;

  const inMonthTimeTimestamp = inMonthTime.getTime();

  const fullFillRequest = async () => {
    const action = 'skip';
    try {
      setLoading(true);
      await axios.post(
        '/api/auth/request',
        {
          email,
          request: action,
          date: nextMonthString,
          subId: subData?.subscriptionId,
          dateTimestamp: inMonthTimeTimestamp,
        },
        null,
      );
      setPerformedAction(true);
      notifications.show({
        title: 'Thank you',
        message:
          'Thank You, your request has been received and will be processed in the next 24 hours.',
      });
      setOpened(false);
      a_PortalAction(action);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      padding="xl"
      opened={opened}
      title="Skip the Month"
      size={isMobile ? 'lg' : 560}
      onClose={() => setOpened(false)}
      position={isMobile ? 'bottom' : 'right'}
      styles={{
        title: {
          fontWeight: 900,
          fontSize: '18px',
          fontFamily: font,
          marginLeft: 'auto',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <p
          style={{
            fontFamily: font,
            fontSize: '16px',
            textAlign: 'center',
            color: 'rgba(0,0,0,.8)',
          }}
        >
          By clicking "Continue," you confirm that you wish to skip this month's
          membership.
        </p>
        <div
          style={{
            padding: '24px',
            display: 'flex',
            borderRadius: '8px',
            alignItems: 'center',
            border: '1px solid #EACFFE',
          }}
        >
          <Checkbox checked={true} style={{ flex: 1 }} color="violet" />
          <p
            style={{
              flex: 2,
              fontSize: '16px',
              fontFamily: font,
            }}
          >
            Until &nbsp;
            <span>{nextMonthString}</span>
          </p>
          <p style={{ flex: 1 }}></p>
        </div>
        <div
          onClick={() => {
            if (loading) {
              return;
            }
            fullFillRequest();
          }}
          style={{
            width: '100%',
            color: 'white',
            height: '54px',
            outline: 'none',
            fontWeight: 800,
            fontSize: '18px',
            fontFamily: font,
            marginTop: '8px',
            textAlign: 'center',
            borderRadius: '24px',
            backgroundColor: '#AD37FD',
          }}
        >
          <div
            style={{
              gap: '8px',
              height: '100%',
              display: 'flex',
              marginTop: 'auto',
              cursor: 'pointer',
              marginBottom: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading && <img src={loader} alt="loader" />}
            Continue
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const RequestRefundModal = ({
  email,
  opened,
  setOpened,
  performedAction,
  setPerformedAction,
}) => {
  const [loading, setLoading] = useState(false);
  const fullFillRequest = async () => {
    const action = 'refund';
    try {
      setLoading(true);
      await axios.post('/api/auth/request', { email, request: action }, null);
      setPerformedAction(true);
      notifications.show({
        title: 'Thank you',
        message:
          'Thank You, your request has been received and will be processed in the next 24 hours.',
      });
      setOpened(false);
      a_PortalAction(action);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      padding="xl"
      opened={opened}
      title="Request Refund"
      size={isMobile ? 'lg' : 560}
      onClose={() => setOpened(false)}
      position={isMobile ? 'bottom' : 'right'}
      styles={{
        title: {
          fontWeight: 900,
          fontSize: '18px',
          fontFamily: font,
          marginLeft: 'auto',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <p
          style={{
            fontFamily: font,
            fontSize: '16px',
            textAlign: 'center',
            color: 'rgba(0,0,0,.8)',
          }}
        >
          By clicking "Continue," you are confirming that you wish to request a
          refund for your last billing period. Please note: The first VIP
          membership charge is non-refundable due to the VIP cashback discount
          applied to your first order.
        </p>
        <div
          onClick={() => {
            if (loading) {
              return;
            }
            fullFillRequest();
          }}
          style={{
            width: '100%',
            color: 'white',
            height: '54px',
            outline: 'none',
            fontWeight: 800,
            fontSize: '18px',
            fontFamily: font,
            marginTop: '8px',
            textAlign: 'center',
            borderRadius: '24px',
            backgroundColor: '#AD37FD',
          }}
        >
          <div
            style={{
              gap: '8px',
              height: '100%',
              display: 'flex',
              cursor: 'pointer',
              marginTop: 'auto',
              marginBottom: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading && <img src={loader} alt="loader" />}
            Continue
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const PauseMembershipModal = ({
  email,
  opened,
  subData,
  setOpened,
  performedAction,
  setPerformedAction,
}) => {
  const now = new Date();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('two-months');

  const inTwoMonthTime = now.setDate(now.getDate() + 60);
  const inThreeMonthTime = now.setDate(now.getDate() + 30);

  const two = new Date(inTwoMonthTime);
  const three = new Date(inThreeMonthTime);

  const twoMonthOption = {
    day: two.getDate(),
    year: two.getFullYear(),
    month: two.toLocaleString('default', { month: 'short' }),
    dayString: two.toLocaleString('default', { weekday: 'short' }),
  };

  const threeMonthOption = {
    day: three.getDate(),
    year: three.getFullYear(),
    month: three.toLocaleString('default', { month: 'short' }),
    dayString: three.toLocaleString('default', { weekday: 'short' }),
  };

  const twoMonthString = `${twoMonthOption.dayString}, ${twoMonthOption.month} ${twoMonthOption.day}, ${twoMonthOption.year}`;
  const threeMonthString = `${threeMonthOption.dayString}, ${threeMonthOption.month} ${threeMonthOption.day}, ${threeMonthOption.year}`;

  const twoMonthsTimeTimestamp = two.getTime();
  const threeMonthsTimeTimestamp = three.getTime();

  const fullFillRequest = async () => {
    const action = `pause-${value}`;
    try {
      setLoading(true);
      await axios.post(
        '/api/auth/request',
        {
          email,
          request: action,
          subId: subData?.subscriptionId,
          date: value === 'two-months' ? twoMonthString : threeMonthString,
          dateTimestamp:
            value === 'two-months'
              ? twoMonthsTimeTimestamp
              : threeMonthsTimeTimestamp,
        },
        null,
      );
      setPerformedAction(true);
      notifications.show({
        title: 'Thank you',
        message:
          'Your request has been received and will be processed within the next 24 hours.',
      });
      setOpened(false);
      a_PortalAction(action);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      padding="xl"
      opened={opened}
      title="Pause Membership"
      size={isMobile ? 'lg' : 560}
      onClose={() => setOpened(false)}
      position={isMobile ? 'bottom' : 'right'}
      styles={{
        title: {
          fontWeight: 900,
          fontSize: '18px',
          fontFamily: font,
          marginLeft: 'auto',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <p
          style={{
            fontFamily: font,
            fontSize: '16px',
            textAlign: 'center',
            color: 'rgba(0,0,0,.8)',
          }}
        >
          Enjoy all the perks without needing to visit the site to shop or skip
          every month.
        </p>
        <Radio.Group value={value} onChange={setValue}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <div
              style={{
                padding: '24px',
                display: 'flex',
                borderRadius: '8px',
                alignItems: 'center',
                border: '1px solid #EACFFE',
              }}
            >
              <div style={{ flex: 1 }}>
                <Radio value="two-months" color="violet" />
              </div>
              <div
                style={{
                  gap: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 700, margin: 0 }}>Two Month</p>
                <p
                  style={{
                    flex: 2,
                    margin: 0,
                    fontSize: '16px',
                    fontFamily: font,
                  }}
                >
                  Until &nbsp;
                  <span>{twoMonthString}</span>
                </p>
              </div>
              <p style={{ flex: 1 }}></p>
            </div>
            <div
              style={{
                padding: '24px',
                display: 'flex',
                borderRadius: '8px',
                alignItems: 'center',
                border: '1px solid #EACFFE',
              }}
            >
              <div style={{ flex: 1 }}>
                <Radio value="three-months" color="violet" />
              </div>
              <div
                style={{
                  gap: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontWeight: 700, margin: 0 }}>Three Month</p>
                <p
                  style={{
                    flex: 2,
                    margin: 0,
                    fontSize: '16px',
                    fontFamily: font,
                  }}
                >
                  Until &nbsp;
                  <span>{threeMonthString}</span>
                </p>
              </div>
              <p style={{ flex: 1 }}></p>
            </div>
          </div>
        </Radio.Group>
        <div
          onClick={() => {
            if (loading) {
              return;
            }
            fullFillRequest();
          }}
          style={{
            width: '100%',
            color: 'white',
            height: '54px',
            outline: 'none',
            fontWeight: 800,
            fontSize: '18px',
            fontFamily: font,
            marginTop: '8px',
            textAlign: 'center',
            borderRadius: '24px',
            backgroundColor: '#AD37FD',
          }}
        >
          <div
            style={{
              gap: '8px',
              height: '100%',
              display: 'flex',
              marginTop: 'auto',
              cursor: 'pointer',
              marginBottom: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading && <img src={loader} alt="loader" />}
            Continue
          </div>
        </div>
      </div>
    </Drawer>
  );
};
